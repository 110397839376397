<template>
  <div class="news">
    <Navigation />
    <img src="~img/news/01.png">
    <consulting />
    <div class="nav hidden-xs-only">
      <ul>
        <li v-for="item in navList" :key="item.id">
          <span @click="toggles(item.id)">{{item.name}}</span>
          <div v-show="item.id == navID" class="dixian"></div>
        </li>
      </ul>
    </div>
    <van-tabs color='#2878ff' class="hidden-sm-and-up" @click="webTaggles">
      <van-tab v-for="item in navList" :key="item.id" :title="item.name" :name="item.id">
      </van-tab>
    </van-tabs>
    <div class="list">
      <ul>
        <li v-for="item in newList" :key="item.id" @click="details(item.id)">
          <img :src="item.image">
          <p class="p1">{{item.title}}</p>
          <p class="p2">{{item.createtime}}</p>
        </li>
      </ul>
      <el-pagination background layout="prev, pager, next" :page-size='12' :total="total" @current-change='changePage'>
      </el-pagination>
    </div>

    <foot />
  </div>
</template>


<script>
import Navigation from "@/components/header.vue";
import consulting from "@/components/consulting.vue";
import footer from "@/components/footer.vue";

export default {
  components: {
    Navigation,
    consulting,
    foot: footer,
  },
  data() {
    return {
      navList: [],
      navID: 1,
      total: 0,
      page: 1,
      newList: [],
      active: 1,
    };
  },
  created() {
    this.getcolumns();
  },
  methods: {
    // 新闻详情
    details(id) {
      this.$router.push({ path: `/details?id=${id}` });
    },
    toggles(id) {
      this.navID = id;
      this.page = 1;
      this.getArticles();
    },
    changePage(value) {
      this.page = value;
      this.getArticles();
    },
    webTaggles(name) {
      this.navID = name;
      this.page = 1;
      this.getArticles();
    },
    async getcolumns() {
      const res = await this.axios.get("api/index/columns");
      if (res.status == 200 && res.data.code == 1) {
        const { data } = res.data;
        this.navList = data;
        this.navID = data[0].id;
        this.getArticles();
      }
    },
    async getArticles() {
      const res = await this.axios.get(
        `api/index/articles?column_id=${this.navID}&page=${this.page}`
      );
      if (res.status == 200 && res.data.code == 1) {
        const { data } = res.data;
        this.total = data.total;
        this.newList = data.data;
        console.log(data);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.news {
  .nav {
    padding-left: 1.34rem;
    padding-top: 0.8rem;
    ul {
      display: flex;
      flex-wrap: wrap;
      margin-left: 0.57rem;
      li {
        margin-right: 1.32rem;
        margin-bottom: 0.4rem;
        span {
          font-size: 0.26rem;
          color: #3e3a3a;
          cursor: pointer;
        }
        .dixian {
          width: 0.55rem;
          height: 0.08rem;
          border-radius: 0.08rem;
          background: #2878ff;
          margin: auto;
          margin-top: 0.05rem;
        }
      }
    }
  }

  .list {
    position: relative;
    ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-left: 1.34rem;
      padding-right: 1.27rem;
      padding-top: 0.56rem;
      padding-bottom: 1rem;
      li {
        width: 5.32rem;
        margin-bottom: 0.57rem;
        cursor: pointer;
        img {
          width: 5.32rem;
        }
        .p1 {
          font-size: 0.22rem;
          color: #3e3a3a;
          line-height: 0.34rem;
          margin-top: 0.3rem;
        }
        .p2 {
          font-size: 0.18rem;
          color: #3e3a3a;
          margin-top: 0.2rem;
        }
      }
    }
    @media (max-width: 750px) {
      ul {
        padding: 0;
        padding-top: 50px;
        padding-bottom: 50px;
        li {
          width: 80%;
          margin: auto;
          margin-bottom: 20px;
        }
      }
    }
  }
  .el-pagination {
    position: absolute;
    bottom: 0.2rem;
    left: 50%;
    transform: translate(-50%);
  }
}
</style>